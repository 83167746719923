import { Link } from '@remix-run/react'
import { cn } from '#app/utils/misc.js'

interface LogoProps {
	className?: string
}

export function Logo({ className }: LogoProps) {
	return (
		<div className={cn('relative', className)}>
			<Link
				to="/"
				className="group relative flex items-center text-xl font-semibold leading-snug"
			>
				<img
					src="/img/symbol/ntu.svg"
					alt="intuizen symbol"
					className="bottom-0 w-8"
				/>
				<span className="relative z-20 ml-2">
					intui<span className="text-brand-gradient">zen</span>
				</span>
			</Link>
		</div>
	)
}
