import { Link, NavLink } from '@remix-run/react'
import { Menu, NotebookPenIcon, Target } from 'lucide-react'
import { useState } from 'react'
import { cn } from '#app/utils/misc.js'
import { useOptionalUser } from '#app/utils/user.js'
import { Button } from './button.tsx'
import { Logo } from './logo.tsx'
import {
	Sheet,
	SheetContent,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from './sheet.tsx'
import { UserDropdown } from './user-dropdown.tsx'

interface HeaderProps {
	featuresEnabled: Record<string, boolean | string | undefined>
}

const navItems = [
	{ label: 'Target Practice', href: '/targets', icon: Target },
	{
		label: 'Sketchbook',
		href: '/draw/host',
		icon: NotebookPenIcon,
		featureFlag: 'sketchbook',
	},
]

export function Header({ featuresEnabled }: HeaderProps) {
	const user = useOptionalUser()

	const [isOpen, setIsOpen] = useState(false)

	const toggle = () => setIsOpen(prev => !prev)

	const filteredNavItems = navItems.filter(item =>
		item.featureFlag ? !!featuresEnabled[item.featureFlag] : true,
	)

	return (
		<header className="fixed left-0 right-0 top-0 z-50 h-16 w-full bg-gray-100 shadow-sm">
			<nav className="container flex flex-wrap items-center gap-2 p-3 text-brand-primary-2 sm:flex-nowrap md:gap-8">
				<Sheet open={isOpen} onOpenChange={toggle}>
					<SheetTrigger asChild>
						<Button className="block sm:hidden" variant="ghost" size="icon">
							<Menu className="inline-block h-6 w-6" />
						</Button>
					</SheetTrigger>
					<SheetContent className="flex flex-col gap-4" side="left">
						<SheetHeader>
							<SheetTitle>
								<Logo />
							</SheetTitle>
						</SheetHeader>
						<hr />
						<div className="flex flex-1 flex-col gap-4">
							{filteredNavItems.map(item => (
								<div className="" key={item.href}>
									<NavLink
										className="font-semibold hover:text-brand-primary-10"
										key={item.href}
										to={item.href}
										onClick={() => setIsOpen(false)}
									>
										{({ isActive }) => (
											<span
												className={cn(
													'flex items-center',
													isActive && 'text-orange-900',
												)}
											>
												<item.icon className="mr-2 inline-block h-6 w-6" />
												{item.label}
											</span>
										)}
									</NavLink>
								</div>
							))}
						</div>
						<Button variant="link" asChild className="mt-4">
							<a
								href="https://twitter.com/intuizentech"
								className="flex items-center"
							>
								Follow on{' '}
								<img
									className="mx-2 inline-block w-4"
									src="/img/logos/logo-black.png"
									alt="intuizen"
								/>
								<span className="text-lg font-semibold">@intuizentech</span>
							</a>
						</Button>
						<p className="text-center text-sm">© 2024 Sovilon Software Inc.</p>
					</SheetContent>
				</Sheet>
				<Logo />
				<div className="flex-1 sm:hidden" />
				<div id="navbar" className="hidden flex-1 gap-4 font-semibold sm:flex">
					{filteredNavItems.map(item => (
						<NavLink
							className="hover:text-brand-primary-10"
							key={item.href}
							to={item.href}
							onClick={() => setIsOpen(false)}
						>
							{({ isActive }) => (
								<span
									className={cn(
										'flex items-center',
										isActive && 'text-orange-900',
									)}
								>
									<item.icon className="mr-1 inline-block h-6 w-6" />
									{item.label}
								</span>
							)}
						</NavLink>
					))}
				</div>
				<div className="flex items-center gap-10 self-end">
					{user ? (
						<UserDropdown />
					) : (
						<Button asChild variant="default" size="lg">
							<Link to="/login">Log In</Link>
						</Button>
					)}
				</div>
			</nav>
		</header>
	)
}
