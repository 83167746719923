import { Form, Link, useSubmit } from '@remix-run/react'
import { CogIcon } from 'lucide-react'
import { useRef } from 'react'
// import { getUserImgSrc } from '#app/utils/misc.js'
// import { useUser } from '#app/utils/user.js'
import { getUserImgSrc } from '#app/utils/misc.js'
import { useOptionalUser } from '#app/utils/user.js'
import { Button } from './button'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuTrigger,
} from './dropdown-menu'
import { Icon } from './icon'

interface UserDropdownProps {}

export function UserDropdown({}: UserDropdownProps) {
	const user = useOptionalUser()
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button asChild variant="secondary">
					<Link
						to={`/users/${user?.username}`}
						onClick={e => e.preventDefault()}
						className="flex items-center gap-2"
					>
						{user?.image ? (
							<img
								className="h-8 w-8 rounded-full object-cover"
								alt={user?.name ?? user?.username}
								src={getUserImgSrc(user?.image?.id)}
							/>
						) : (
							<Icon className="text-body-md" name="avatar"></Icon>
						)}
						<span className="hidden text-body-sm font-bold md:block">
							{user?.name ?? user?.username}
						</span>
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="start">
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user?.username}`}>
							<Icon className="text-body-md" name="avatar">
								Profile
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={'/settings/configuration'}>
							<div className="flex items-center gap-1.5">
								<CogIcon className="w-5 text-body-md" />
								Settings
							</div>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem
						asChild
						onSelect={event => {
							event.preventDefault()
							submit(formRef.current)
						}}
					>
						<Form action="/logout" method="POST" ref={formRef}>
							<Icon className="text-body-md" name="exit">
								<button type="submit">Logout</button>
							</Icon>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}
